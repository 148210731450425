import React from 'react';
import { Grid, Header, Message, Segment } from 'semantic-ui-react'
// import Layout,{DefaultHelmet}  from '../components/layout';
import { NoHeadFootLayout } from '../components/layout';
import {
	SignInGoogle,
	SignInFacebook,
	SignInTwitter,
} from '../components/SignIn';

const SignInPage = () => (
	// <Fragment>
	<Grid textAlign='center' style={{ height: '100vh', width: '100%', marginLeft: '0', marginRight: '0' }} verticalAlign='middle' centered={true}> 
		<Grid.Column style={{ maxWidth: 512 }}>
			<img src='/icons/icon-512x512.png' width="100%" height="100%" />

			<Segment>
				{/* <SignInForm /> */}
				<SignInGoogle />
			</Segment>
		</Grid.Column>
	</Grid>
	// </Fragment>
);

export default () => (
	<NoHeadFootLayout>
		<SignInPage />
	</NoHeadFootLayout>
);
